export const API_LOGIN_FIREBASE = process.env.VUE_APP_API_URL + '/v1/auth/firebase'
export const API_CATEGORIES = process.env.VUE_APP_API_URL + '/v1/categories'
export const API_CATEGORY_CREATE = process.env.VUE_APP_API_URL + '/v1/categories'
export const API_PROMPT_CREATE = process.env.VUE_APP_API_URL + '/v1/prompts'
export const API_GET_LIST_PROMPT = process.env.VUE_APP_API_URL + '/v1/prompts'
export const API_DELETE_PROMPT = process.env.VUE_APP_API_URL + '/v1/prompts/deletes'
export const API_UNDO_PROMPT = process.env.VUE_APP_API_URL + '/v1/prompts/restores'
export const API_GET_DETAIL_PROMPT = process.env.VUE_APP_API_URL + '/v1/prompts/:id'
export const API_UPDATE_PROMPT = process.env.VUE_APP_API_URL + '/v1/prompts/:id'
export const API_POSTS = process.env.VUE_APP_API_URL + '/v1/posts'
export const API_POST_CREATE = process.env.VUE_APP_API_URL + '/v1/posts/:id'
export const API_CREATE_CONTENT = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants/generates/contents'
export const API_RENDER_IMAGE = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants/generates/thumbnails'
export const API_RENDER_TAGS = process.env.VUE_APP_API_URL + '/v1/posts/render-tags'
export const API_UPLOAD_IMAGES = process.env.VUE_APP_API_URL + '/v1/resources/images'
export const API_REMOVE_IMAGES = process.env.VUE_APP_API_URL + '/v1/resources/images/deletes'
export const API_DETAIL_POST = process.env.VUE_APP_API_URL + '/v1/posts/:id'
export const API_CONTRACTS = process.env.VUE_APP_API_URL + '/v1/contracts'
export const API_AIS = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants'
export const API_CREATE_AI = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants'
export const API_ACCOUNTS = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts'
export const API_CREATE_ACCOUNT = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts'
export const API_CREATE_PLANNING = process.env.VUE_APP_API_URL + '/v1/plans'
export const API_GET_PLANNING_DETAIL = process.env.VUE_APP_API_URL + '/v1/plans/:id'
export const API_GET_LIST_TOPIC = process.env.VUE_APP_API_URL + '/v1/plans/:id/tasks'
export const API_CREATE_TOPIC = process.env.VUE_APP_API_URL + '/v1/plans/:id/tasks'
export const API_UPDATE_TOPIC = process.env.VUE_APP_API_URL + '/v1/plans/:id/tasks/:detailId'
export const API_DETELE_TOPIC = process.env.VUE_APP_API_URL + '/v1/plans/:id/tasks/:detailId'
export const API_DETAIL_TOPIC = process.env.VUE_APP_API_URL + '/v1/plans/:id/tasks/:detailId'
export const API_GET_LIST_PLAN = process.env.VUE_APP_API_URL + '/v2/plans'
export const API_UPDATE_PLAN = process.env.VUE_APP_API_URL + '/v1/plans/:id'
export const API_DETELE_PLAN = process.env.VUE_APP_API_URL + '/v1/plans/:id'
export const API_CATEGORY_ASSISTANT = process.env.VUE_APP_API_URL + '/v1/categories/assistants'
export const API_UPLOAD_FILE_PLAN = process.env.VUE_APP_API_URL + '/v1/plans/:id/tasks/imports'
export const API_UPDATE_ASSISTANT = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants/:id'
export const API_DETELE_ASSISTANT = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants/:id'
export const API_DETAIL_ASSISTANT = process.env.VUE_APP_API_URL + '/v1/ai-models/assistants/:id'
export const API_UPDATE_ACCOUNT = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts/:id'
export const API_DETELE_ACCOUNT = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts/:id'
export const API_DETAIL_ACCOUNT = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts/:id'
export const API_FANPAGE_ACCOUNT = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts/:id/fanpages'
export const API_UPLOAD_LINK_FILE_PLAN = process.env.VUE_APP_API_URL + '/v2/tasks/import'
export const API_USERS = process.env.VUE_APP_API_URL + '/v1/contacts/assignees'
export const API_GET_LIST_TOPIC_DASHBOARD = process.env.VUE_APP_API_URL + '/v1/dashboards'
export const API_CREATE_ACCOUNT_FACEBOOK = process.env.VUE_APP_API_URL + '/v1/plans/posting-accounts/connects/facebook'
export const API_GET_PERMISSIONS = process.env.VUE_APP_API_URL + '/v1/contacts/permissions'
export const API_ASSIGNED = process.env.VUE_APP_API_URL + '/v1/contacts/tasks/assign'
export const API_LIST_ROLE = process.env.VUE_APP_API_URL + '/v1/roles'
export const API_CREATE_ROLE = process.env.VUE_APP_API_URL + '/v1/roles'
export const API_UPDATE_ROLE = process.env.VUE_APP_API_URL + '/v1/roles/:id'
export const API_DELETE_ROLE = process.env.VUE_APP_API_URL + '/v1/roles/:id'
export const API_DETAIL_ROLE = process.env.VUE_APP_API_URL + '/v1/roles/:id'
export const API_LIST_PERMISSION = process.env.VUE_APP_API_URL + '/v1/permissions'
export const API_STARTWRITINGPOST = process.env.VUE_APP_API_URL + '/v1/tasks/:id/touch'
export const API_LOCK_TASK = process.env.VUE_APP_API_URL + '/v1/tasks/:id/lock'
export const API_UNLOCK_TASK = process.env.VUE_APP_API_URL + '/v1/tasks/:id/unlock'
export const API_MEMBERS = process.env.VUE_APP_API_URL + '/v1/contacts/staffs'
export const API_UPDATE_MEMBER = process.env.VUE_APP_API_URL + '/v1/contacts/:id/roles/manage'
export const API_REQUEST_REVIEW = process.env.VUE_APP_API_URL + '/v1/tasks/:id/request-review'
export const API_DENY_REVIEW= process.env.VUE_APP_API_URL + '/v1/tasks/:id/deny-review'
export const API_REQUEST_APPPROVE = process.env.VUE_APP_API_URL + '/v1/tasks/:id/request-approve'
export const API_DENY_APPROVE = process.env.VUE_APP_API_URL + '/v1/customers/tasks/:id/deny-approve'
export const API_APPROVE = process.env.VUE_APP_API_URL + '/v1/customers/tasks/:id/approve'
export const API_PUBLISH_POST = process.env.VUE_APP_API_URL + '/v2/tasks/:id/posts'
export const API_GET_LIST_COMPLAINT_REVIEW = process.env.VUE_APP_API_URL + '/v1/posts/:id/complaints/review'
export const API_GET_LIST_COMPLAINT_APPROVE = process.env.VUE_APP_API_URL + '/v1/posts/:id/complaints/approve'
export const API_CREATE_COMPLAINT = process.env.VUE_APP_API_URL + '/v1/complaints'
export const API_DELETE_COMPLAINT = process.env.VUE_APP_API_URL + '/v1/complaints/:id'
export const API_UPLOAD_IMAGE_COMPLAINT = process.env.VUE_APP_API_URL + '/v1/resources/images/complaints'
export const API_LOGOUT = process.env.VUE_APP_API_URL + '/v1/auth/logout'
export const API_GET_PROFILE = process.env.VUE_APP_API_URL + '/v2/contacts/me'
export const API_UPDATE_PROFILE = process.env.VUE_APP_API_URL + '/v1/contacts/personal'
export const API_VERIFY_REVIEW = process.env.VUE_APP_API_URL + '/v1/customers/verify'

export const API_DETAIL_POST_CUSTOMER = process.env.VUE_APP_API_URL + '/v1/customers/posts/:id'
export const API_GET_LIST_COMPLAINT_APPROVE_CUSTOMER = process.env.VUE_APP_API_URL + '/v1/customers/posts/:id/complaints/approve'
export const API_CREATE_COMPLAINT_CUSTOMER = process.env.VUE_APP_API_URL + '/v1/customers/complaints'
export const API_DELETE_COMPLAINT_CUSTOMER = process.env.VUE_APP_API_URL + '/v1/customers/complaints/:id'
export const API_UPLOAD_IMAGE_COMPLAINT_CUSTOMER = process.env.VUE_APP_API_URL + '/v1/customers/resources/images/complaints'

export const API_GET_SPREADSHEET_SHEET = process.env.VUE_APP_API_URL + '/v1/resources/spreadsheets/:id/sheets'

export const API_GET_TASK_CUSTOMER_INFO = process.env.VUE_APP_API_URL + '/v2/tasks/:id/notify-preview'

export const API_ZALOACCOUNTS = process.env.VUE_APP_API_URL + '/v1/third-parties/zalo-oa/followers'

export const API_CUSTOMERS = process.env.VUE_APP_API_URL + '/v1/customers'
export const API_GET_CUSTOMER_DETAIL = process.env.VUE_APP_API_URL + '/v1/customers/:id'
export const API_LINK_ZALO_OA_ACCOUNT = process.env.VUE_APP_API_URL + '/v1/customers/:id/link-with-zoa'


export const NOTIFICATIONS = process.env.VUE_APP_API_URL + '/v1/notifications'
export const NOTIFICATIONS_READ = process.env.VUE_APP_API_URL + '/v1/notifications'
export const NOTIFICATIONS_UNREAD = process.env.VUE_APP_API_URL + '/v1/notifications/unread'

export const NOTIFICATIONS_INDUSTRY = process.env.VUE_APP_API_URL + '/v1/plan-industries'

export const API_POST_DETAIL_V2 = process.env.VUE_APP_API_URL + '/v2/dashboards/posts/:uuid'
export const API_POST_REPORT = process.env.VUE_APP_API_URL + '/v2/dashboards/posts/:uuid'
export const API_POST_WATCHERS = process.env.VUE_APP_API_URL + '/v2/dashboards/posts/:uuid/watchers'
export const API_TASK_ASSIGNEE = process.env.VUE_APP_API_URL + '/v2/tasks/assignees'
export const API_TASK_V2 = process.env.VUE_APP_API_URL + '/v2/tasks/:id'
export const API_TASK_UNAPPROVE = process.env.VUE_APP_API_URL + '/v2/tasks/:id/unapprove'
export const API_RESOURCE_V2 = process.env.VUE_APP_API_URL + '/v2/resources'
export const API_PLAN_V2= process.env.VUE_APP_API_URL + '/v2/plans'

export const API_MEMBER_V2 = process.env.VUE_APP_API_URL + '/v2/staffs'

export const API_USER_V2 = process.env.VUE_APP_API_URL + '/v2/users'